/* eslint-disable no-console */
import i18n from "i18next";
import has from "lodash.has";
import { initReactI18next } from "react-i18next";

import translationEn from "./locales/en/translation.json";
import translationDe from "./locales/de/translation.json";
import { ConfigState } from "./store/definitions";

const i18nInit = async (originalConfig: ConfigState) =>
    i18n.use(initReactI18next).init({
        supportedLngs: ["de-DE", "en-GB", "en", "de"],
        lng: originalConfig?.lng ?? "en-GB",
        fallbackLng: "en",
        resources: {
            en: { translation: translationEn },
            de: { translation: translationDe }
        },
        debug: /(\.dev\.wrisk\.co|localhost)$/im.test(window.location.hostname),
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        saveMissing: true,
        missingInterpolationHandler: (text) => {
            console.error(`i18n: Missing interpolation for "${text}"`);
        },
        missingKeyHandler: (_, __, key, fallbackValue, ___, options) => {
            if (!has(options, "defaultValue")) {
                console.error(`i18n: Missing key ${key} and using fallback ${fallbackValue}`);
            }
        }
    });

export default i18nInit;
